import { Actions, createEffect, ofType } from "@ngrx/effects";
import { exhaustMap, map } from "rxjs/operators";
import { getUserMenus, setUserMenu } from "./preferences.actions";
import { Injectable } from "@angular/core";
import { AuthService } from "../../core/services/auth.service";

@Injectable()
export class PreferencesEffects {
  constructor(private actions: Actions, private authService: AuthService) {}

  getUserMenus = createEffect(() =>
    this.actions.pipe(
      ofType(getUserMenus),
      exhaustMap((action) => {
        return this.authService.getMenus(action.userId).pipe(map((MenuItem) => setUserMenu(MenuItem)));
      })
    )
  );
}

