import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from '../../root-store/root-state';
import { selectSessionToken } from '../../root-store/session/session.selectors';
import { catchError } from 'rxjs/operators';
import { clearSessionData } from '../../root-store/session/session.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private store: Store<State>,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authToken: string;

    this.store
      .pipe(select(selectSessionToken))
      .subscribe(token => {
        authToken = token;
      });

    if(authToken) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
    }

    return next.handle(req).pipe(
      catchError(error => {
        if(error.status === 401) {
          this.store.dispatch(clearSessionData());
        }

        return throwError(error);
      })
    );
  }

}
