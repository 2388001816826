import { Injectable } from '@angular/core';
import smoothscroll from 'smoothscroll-polyfill';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  windowWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor( ) {
    smoothscroll.polyfill();
  }

  scrollTo(position: number): void {
    window.scroll({ top: position, behavior: 'smooth' });
  }

  open(link: string, target: string): void {
    window.open(link, target);
  }

}
