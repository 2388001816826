import { ConfigService } from './config.service';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { MsalScopes } from '../models/msal-scopes.enum';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class MsalConfigService  {
  private b2cPolicies: any;
  private apiUriScope: string;
  private msalConfig: any;
  private isIE: boolean;
  private authority: string;

  constructor(private configService: ConfigService, private cookieService: CookieService) {
    this.authority = this.cookieService.get('msal:authority');
    this.apiUriScope = `https://${this.configService.b2c.domain}/${this.configService.b2c.clientId}`;
    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    this.b2cPolicies = this.getB2cPolicies();
    this.msalConfig = this.getMsalConfig();
  }

  private getMsalConfig() {
    return {
      auth: {
        clientId: this.configService.b2c.clientId,
        knownAuthorities: [this.b2cPolicies.authorityDomain],
        redirectUri: this.configService.b2c.redirectUrl,
        authority: this.authority || this.b2cPolicies.authorities.signUpSignIn.authorityWithMfa,
        postLogoutRedirectUri: this.configService.b2c.postLogoutRedirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: this.isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback: (logLevel, message, containsPii) => {
            console.log(message);
          },
          logLevel: LogLevel.Verbose,
          piiLoggingEnabled: false
        }
      }
    };
  }

  getProtectedResourceMap = () => {
    const resourceMap = new Map<string, Array<string | null>>();
    // resourceMap.set(`${this.configService.identityUrl}loginb2c`, [`${this.apiUriScope}/${MsalScopes.ApiAll}`]);

    return resourceMap;
  }



  getB2cPolicies() {
    return {
      authorities: {
        signUpSignIn: {
          authorityWithMfa: `https://${this.configService.b2c.tenantName}.b2clogin.com/${this.configService.b2c.domain}/${this.configService.b2c.policies.names.smsOrPhoneCall}`,
          authorityWithMfaConditional: `https://${this.configService.b2c.tenantName}.b2clogin.com/${this.configService.b2c.domain}/${this.configService.b2c.policies.names.smsOrPhoneCallConditional}`,
          authorityWithEmail: `https://${this.configService.b2c.tenantName}.b2clogin.com/${this.configService.b2c.domain}/${this.configService.b2c.policies.names.emailOnly}`,
          authorityWithEmailConditional: `https://${this.configService.b2c.tenantName}.b2clogin.com/${this.configService.b2c.domain}/${this.configService.b2c.policies.names.emailOnlyConditional}`,
          authoritySFA: `https://${this.configService.b2c.tenantName}.b2clogin.com/${this.configService.b2c.domain}/${this.configService.b2c.policies.names.sfa}`
        },
      },
      authorityDomain: `${this.configService.b2c.tenantName}.b2clogin.com`
    };
  }

  createInstance(): IPublicClientApplication {
    return new PublicClientApplication({
      ...this.msalConfig,
    });
  }

  createGuardConfig(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [MsalScopes.Default]
      }
    };
  }

  createInterceptorConfig(): MsalInterceptorConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: this.getProtectedResourceMap(),
    };
  }
}
