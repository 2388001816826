export const environment = {
  production: true,
  dev: false,
  showCustomerPortalLink: false,
  identityUrl: 'https://mo.api.aulcorp.com/',
  dealerUrl: 'https://mo.api.aulcorp.com/',
  ratesUrl: 'https://mo.api.aulcorp.com/rates/',
  gapUrl: 'https://mo.api.aulcorp.com/',
  sppUrl: 'https://mo.api.aulcorp.com/spp/',
  ullUrl: 'https://mo.api.aulcorp.com/ull/',
  reportsUrl: 'https://mo.api.aulcorp.com/reports/',
  quoteAndSalesUrl: 'https://mo.secure.aulcorp.com',
  aulCorpUrl: 'https://mo.adl.aulcorp.com',
  revertUrl: 'https://mo.rev.aulcorp.com',
  legacyLoginUrl: 'Adllogin.aspx',
  customerPortalUrl: 'https://customer.aulcorp.com/login',
  b2c: {
    tenantId: 'cba190be-bac0-4783-9907-ce09ecd24b76',
    clientId: '8bc07481-1666-4d8a-9f88-5aa72432699e',
    tenantName: 'mProtectiveAPDMFA',
    domain: 'mProtectiveAPDMFA.onmicrosoft.com',
    redirectUrl: '/',
    postLogoutRedirectUri: '/logout-redirect',
    policies : {
      names: {
        smsOrPhoneCall: 'B2C_1_UserIDSignInWithMFA',
        emailOnly: 'B2C_1_UserIDSignInWithEmailMFA',
        smsOrPhoneCallConditional: 'B2C_1_UserIDSignInWithConditionalMFA',
        emailOnlyConditional: 'B2C_1_UserIDSignInWithEmailConditionalMFA',
        sfa: 'B2C_1_UserIDSignInWithMFA'
      }
    },
  },
};
