import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  _spinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  spinner$: Observable<boolean> = this._spinner.asObservable();
  _overrideSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  overrideSpinner$: Observable<boolean> = this._overrideSpinner.asObservable();

  constructor() { }

  toggleSpinner(show: boolean): void {
    const timeoutId = setTimeout(() => {
      this._spinner.next(show);
      clearTimeout(timeoutId);
    });
  }

  toggleOverrideSpinner(show: boolean): void {
    this._overrideSpinner.next(show);
  }

}
