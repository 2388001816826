import { Action, createReducer, on } from '@ngrx/store';
import { PayeePacksState, payeePacksInitialState } from './payee-packs.state';
import { setPayeePacksData, unselectPayeePacksData } from './payee-packs.actions';

const reducer = createReducer(
  payeePacksInitialState,
  on(setPayeePacksData, (state, value) => ({ ...state, payeePacksSelected: value })),
  on(unselectPayeePacksData, () => payeePacksInitialState)
);

export function payeePacksReducer(
  state: PayeePacksState | undefined,
  action: Action
){
  return reducer(state, action);
}
