export { }

declare global { 
  interface StringConstructor {
    isNullOrEmpty(value: string): boolean;
  }
}

String.isNullOrEmpty = function (value: string): boolean {
  return value === null || value === undefined || value.trim() === '';
};

