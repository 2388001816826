import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { signAsDealerReducer } from './sign-as-dealer.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SignAsDealerEffects } from './sign-as-dealer.effects';
import { DealerService } from '../../secure/services/dealer.service';
import { LocalStorageService } from '../../core/services/localstorage.service';
import { storageMetaReducer } from './storage.metareducer';
import { SIGNASDEALER_LOCAL_STORAGE_KEY, SIGNASDEALER_STORAGE_KEYS, SIGNASDEALER_CONFIG_TOKEN } from './sign-as-dealer.tokens';

export function getDealerConfig(saveKeys: string[], localStorageKey: string, storageService: LocalStorageService) {
  return { metaReducers: [storageMetaReducer(saveKeys, localStorageKey, storageService)]};
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('signAsDealer', signAsDealerReducer, SIGNASDEALER_CONFIG_TOKEN),
    EffectsModule.forFeature([ SignAsDealerEffects ]),
  ],
  providers: [
    SignAsDealerEffects,
    DealerService,
    { provide: SIGNASDEALER_LOCAL_STORAGE_KEY, useValue: 'signAsDealer'},
    { provide: SIGNASDEALER_STORAGE_KEYS, useValue: ['selected']},
    { provide: SIGNASDEALER_CONFIG_TOKEN,
      deps: [SIGNASDEALER_STORAGE_KEYS, SIGNASDEALER_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getDealerConfig
    },
  ]
})
export class SignAsDealerModule { }
