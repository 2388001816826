import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpInterceptor } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalRedirectComponent,
  MsalService } from '@azure/msal-angular';

import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { RootStoreModule } from '../root-store/root-store.module';
import { AuthInterceptor } from './services/auth.interceptor';
import { MsalConfigService } from './services/msal-config.service';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { FileSaverModule } from 'ngx-filesaver';
import { SpinnerHttpInterceptorService } from './services/spinner-http-interceptor.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    RootStoreModule,
    MatDialogModule,
    FileSaverModule,
    ToastrModule.forRoot({
      timeOut: 7000,
      closeButton: true,
      tapToDismiss: false,
      preventDuplicates: true
    })
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerHttpInterceptorService,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: (msalConfigService: MsalConfigService) => {
        return msalConfigService.createInstance();
      },
      deps: [MsalConfigService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (msalConfigService: MsalConfigService) => {
        return msalConfigService.createGuardConfig();
      },
      deps: [MsalConfigService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (msalConfigService: MsalConfigService) => {
        return msalConfigService.createInterceptorConfig();
      },
      deps: [MsalConfigService]
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalConfigService
  ],
  bootstrap: [
    MsalRedirectComponent
  ]
})
export class CoreModule { }
