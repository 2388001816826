import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from './core/services/navigation.service';
import { SessionState } from './root-store/session/session.state';
import { Store } from '@ngrx/store';
import { readAuthCookie } from './root-store/session/session.actions';
import { QuoteSalesService } from './core/services/quote-sales.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { windowToken } from './core/services/window';
import { WindowService } from './core/services/window.service';

@Component({
  selector: 'adl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('authIFrame') authIFrame: ElementRef;

  isPageEmbedded: boolean;

  constructor(
    private navigationService: NavigationService,
    private store: Store<SessionState>,
    public quoteSalesService: QuoteSalesService,
    private windowService: WindowService,
    @Inject(windowToken) private window: Window
  ) { }

  ngOnInit() {
    this.navigationService.init();
    this.store.dispatch(readAuthCookie());

    this.onAuthIFrame().subscribe(iFrame => {
      this.quoteSalesService.setAuthIFrame(iFrame);
    });

    this.window.addEventListener('message', event => this.quoteSalesService.onMessage(event), false);
    this.windowService.windowWidth$.next(this.window.innerWidth);
    this.window.onresize = () => {
      this.windowService.windowWidth$.next(this.window.innerWidth);
    };
    this.quoteSalesService.embedded$.subscribe(embedded => {
      this.isPageEmbedded = embedded;
    });
  }

  onAuthIFrame(): Observable<HTMLIFrameElement> {
    return this.quoteSalesService.legacyLoginUrl
      .pipe(
        filter(url => !!url),
        switchMap(() => timer()),
        map(() => this.authIFrame.nativeElement as HTMLIFrameElement)
      );
  }

}
