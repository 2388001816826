import { UserPreferences } from '../../secure/models/user-preferences';
import { MenuItem } from '../../secure/models/menu-item';

export interface PreferencesState {
  preferences: UserPreferences;
}

export const preferencesInitialState: PreferencesState = {
  preferences: null
};

export interface MenuPreferencesState {
  menuPreferences: MenuItem;
}

export const MenupreferencesInitialState: MenuPreferencesState = {
  menuPreferences: null
};
