import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, AsyncSubject } from 'rxjs';
import { Router, NavigationEnd, ResolveStart, Data, ActivationStart } from '@angular/router';
import { filter, map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _activeRoute: BehaviorSubject<string> = new BehaviorSubject(null);
  activeRoute: Observable<string>;
  private _isLoadingRoute: Subject<boolean> = new Subject();
  isLoadingRoute: Observable<boolean>;
  private _routeData: BehaviorSubject<Data> = new BehaviorSubject(null);
  routeData: Observable<Data>;
  private initializer = new AsyncSubject<boolean>();
  ready: Observable<boolean> = this.initializer.asObservable();

  constructor(
    private router: Router
  ) { }

  init(): void {
    this.trackActiveRoute();
    this.trackLoadingStatus();
    this.trackRouteData();
    this.notifyReady();
  }

  private notifyReady(): void {
    this.initializer.next(true);
    this.initializer.complete();
  }

  private trackActiveRoute(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects.replace('/', ''))
      )
      .subscribe(routePath => {
        this._activeRoute.next(routePath);
      });

    this.activeRoute = this._activeRoute.asObservable().pipe(
      skipWhile(path => path === null)
    );
  }

  private trackLoadingStatus(): void {
    this.router.events.pipe(
      filter(event => event instanceof ResolveStart || event instanceof NavigationEnd),
      map(event => event instanceof ResolveStart)
    ).subscribe(loading => {
      this._isLoadingRoute.next(loading);
    });

    this.isLoadingRoute = this._isLoadingRoute.asObservable();
  }

  private trackRouteData(): void {
    this.router.events.pipe(
      filter(event => event instanceof ActivationStart),
      map((event: ActivationStart) => event.snapshot.data)
    ).subscribe(routeData => {
      this._routeData.next(routeData);
    });

    this.routeData = this._routeData.asObservable();
  }

}
