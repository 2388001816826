import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { SessionState } from './session.state';
import { UserTypes } from '../../core/models/user-types';
import { State } from '../root-state';
import { filter } from 'rxjs/operators';
import { pipe } from 'rxjs';

export const selectSession = createFeatureSelector<State, SessionState>('session');

export const selectSessionIsLoggedIn = createSelector(
  selectSession,
  state => !!(state.authToken && state.user)
);

export const selectSessionUser = createSelector(
  selectSession,
  state => state.user
);

export const selectSessionToken = createSelector(
  selectSession,
  state => state.authToken
);

export const selectSessionMenuType = createSelector(
  selectSession,
  state => {
    if(!state.user) {
      return null;
    }

    switch (state.user.type) {
      case UserTypes.Agent:
      case UserTypes.SubAgent:
      case UserTypes.AgentCorp:
      case UserTypes.AgentGroup:
        return 'agent';
      case UserTypes.Dealer:
      case UserTypes.DealerEmp:
        return 'dealer';
      case UserTypes.Lender:
      case UserTypes.LenderEmp:
        return 'lender';
      case UserTypes.DealerGroup:
        return 'dealerGroup';
      case UserTypes.DealerGroupEmp:
        return 'dealerGroupEmp';
      case UserTypes.CorpGroup:
        return 'corpGroup';
      case UserTypes.CorpGroupEmp:
        return 'corpGroupEmp';
      case UserTypes.AgentPack:
        return 'agentPack';
      case UserTypes.DealerPassThrough:
        return 'dealerPassThrough';
      case UserTypes.LenderPassThrough:
        return 'lenderPassThrough';
      case UserTypes.IntegrationPartnerPack:
        return 'integrationPartnerPack';
      case UserTypes.AULAdmin:
        return 'admin';
    }
  }
);

export const selectSessionOnNewAuthToken = pipe(
  select(selectSession),
  filter(state => !!state.authToken)
);
