import { DealerInfo } from 'src/app/secure/models/dealer-info';

export interface SignAsDealerState {
  list: DealerInfo[];
  selected: DealerInfo;
}

export const signAsDealerInitialState: SignAsDealerState = {
  list: null,
  selected: null
};
