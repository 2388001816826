import { User } from '../../core/models/user';

export interface SessionState {
  authToken: string;
  user: User;
  pickup: boolean;
}

export const sessionInitialState: SessionState = {
  authToken: null,
  user: null,
  pickup: false
};
