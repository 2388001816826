import { Injectable } from '@angular/core';
import { VSCRateForm } from '../models/vsc-rate-form';
import { Rate } from '../models/rate';
import * as _ from 'lodash';
import { DefaultRules } from '../models/default-rules';
import { RateOptions } from '../models/rate-options';
import { BehaviorSubject, Observable } from 'rxjs';
import { LienholderInfo } from '../models/lienholder-info';
import { lienholderTypes } from '../constants/lienholder-types';
import { Program } from '../models/program';
import { SearchInformation } from '../models/search-information';
import { FormArray } from '@angular/forms';
import { VscContractPricing } from '../models/vsc-contract-pricing';

@Injectable({
  providedIn: 'root'
})
export class RatesUIService {
  private _contractNumber: string = null;
  _contractGenerationLocked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  contractGenerationLocked: Observable<boolean> = this._contractGenerationLocked.asObservable();
  buseSelected = false;

  constructor() { }

  static calcOptionTotal(options: RateOptions, deductibleAmount: number): number {
    const subTotals = [deductibleAmount];

    _.forOwn(options, optionValue => {
      if (_.isNumber(optionValue)) {
        subTotals.push(optionValue);
      }
    });

    return subTotals.reduce((total, current) => total + current, 0);
  }

  showInServiceDate(vscRateForms: VSCRateForm[]): boolean {
    return vscRateForms.some(vscRateForm => {
      const { rate, program, options } = vscRateForm;
      return rate && !!(program.inServiceDate || options.wr);
    });
  }

  getContractByProgram(contracts: VscContractPricing[], program: Program): VscContractPricing {
    return contracts.filter(contract => contract.pricing.programCode === program.code).pop();
  }

  optionIsDefault(defaultRules: DefaultRules[], rate: Rate, age: number, mileage: number, code: string): boolean {
    if (code.toLocaleLowerCase() === 'buse' && !this.buseSelected) {
      return false;
    }
    if(!defaultRules) {
      return true;
    }
    return defaultRules.some(ruleSet => {
      return  _.toPairs(ruleSet).every(rule => {
        switch (rule[0]) {
          case 'class':
          case 'planMiles':
          case 'planMonths':
          case 'coverage':
            return rule[1] === rate[rule[0]];

          case 'ageFrom':
          case 'ageTo':
            return this.isInRange(age, ruleSet.ageFrom, ruleSet.ageTo);

          case 'mileageFrom':
          case 'mileageTo':
            return this.isInRange(mileage, ruleSet.mileageFrom, ruleSet.mileageTo);
        }
      });
    });
  }

  checkSpecialLienholderSelected(lienholder: LienholderInfo): string {
    if (lienholder) {
      const id = lienholder.id;
      return (id === lienholderTypes.spp.id) ? 'spp' : (id === lienholderTypes.universallendersllc.id) ? 'universal' : (id === lienholderTypes.servicepaymentplanb.id) ? 'sppb' : null;
    }
    return null;
  }

  showWarning(program: Program, searchInformation: SearchInformation, vscRateForms: FormArray, hasSelectedRate: boolean): boolean {
    if (program.upsellPrograms && searchInformation.vin) {
      const isUpsellSelectedRate = program.upsellPrograms.some(upsell => (vscRateForms.value.map(form => form.program.code).indexOf(upsell) !== -1));
      return !(hasSelectedRate && isUpsellSelectedRate);
    }
    return false;
  }

  private isInRange(valueToEvaluate: number, from: number, to: number): boolean {
    return valueToEvaluate >= from && valueToEvaluate <= to;
  }

  lockContractGeneration(): void {
    this._contractGenerationLocked.next(true);
  }

  unlockContractGeneration(): void {
    this._contractGenerationLocked.next(false);
  }

  get contractNumber(): string {
    return this._contractNumber;
  }

  set contractNumber(value: string) {
    this._contractNumber = value;
  }

}
