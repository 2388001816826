import { Action, createReducer, on } from '@ngrx/store';
import { sessionInitialState, SessionState } from './session.state';
import { clearSessionData, setSessionData } from './session.actions';

const reducer = createReducer(
  sessionInitialState,
  on(setSessionData, (state, { user, authToken, pickup }) => ({
    ...state, user, authToken, pickup
  })),
  on(clearSessionData, () => sessionInitialState)
);

export function sessionReducer(state: SessionState | undefined, action: Action) {
  return reducer(state, action);
}
