import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getDealerList, setDealerList, impersonate as impersonate, selectDealer, unselectDealer } from './sign-as-dealer.actions';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { DealerService } from '../../secure/services/dealer.service';
import { of } from 'rxjs';

@Injectable()
export class SignAsDealerEffects {

  constructor(
    private actions: Actions,
    private dealerService: DealerService
  ) { }

  getDealerList = createEffect(() => this.actions.pipe(
    ofType(getDealerList),
    exhaustMap(action => {
      return this.dealerService.getAllForUser(action.params).pipe(
        map(list => setDealerList({ list }))
      );
    })
  ));

  impersonate = createEffect(() => this.actions.pipe(
    ofType(impersonate),
    exhaustMap(action => {
      return this.dealerService.impersonate(action.selected.id, action.canImpersonate).pipe(
        map(() => {
          if (action.selected.id) {
            return selectDealer({ selected: action.selected });
          } else {
            return unselectDealer();
          }
        }),
        catchError(() => {
          if (action.selected.id) {
            return of(unselectDealer());
          } else {
            return of(selectDealer({ selected: action.selected }));
          }
        })
      );
    })
  ));

}


