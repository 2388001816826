import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionModule } from './session/session.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { SignAsDealerModule } from './sign-as-dealer/sign-as-dealer.module';
import { PreferencesModule } from './preferences/preferences.module';
import { PayeePacksModule } from './payee-packs/payee-packs.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SessionModule,
    SignAsDealerModule,
    PreferencesModule,
    PayeePacksModule,
    StoreModule.forRoot({ }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    })
  ]
})
export class RootStoreModule { }
