import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { LoginData } from '../models/login-data';
import { first, map } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { UserPreferences } from '../../secure/models/user-preferences';
import { MenuItem } from 'src/app/secure/models/menu-item';
import { encode } from 'punycode';
import { SecondAuthMethod } from '../models/second-auth-method';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlToRedirect: string;

  constructor(
    private api: ApiService,
    private config: ConfigService
  ) { }

  login(credentials: LoginData): Observable<string> {
    return this.api.post<{ token: string }>(this.apiUrl('login'), credentials).pipe(
      map(response => response.token)
    );
  }

  loginMFA(credentials: LoginData): Observable<string> {
    const { token, ...restCreds } = credentials;
    return this.api.post<{ token: string }>(this.apiUrl('loginb2c'), restCreds).pipe(
      map(response => response.token)
    );
  }

  loginMFAWithHeader(credentials: LoginData): Observable<string> {
    const { token, ...restCreds } = credentials;
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this.api.postWithHeaders<{ token: string }>(this.apiUrl('loginb2c'), restCreds, { headers }).pipe(
      map(response => response.token)
    );
  }

  logout(): Observable<boolean> {
    this.urlToRedirect = null;
    return this.api.post(this.apiUrl('logout')).pipe(map(() => true));
  }

  refreshToken(): Observable<string> {
    return this.api.post<{ token: string }>(this.apiUrl('refresh-token'))
      .pipe(map(data => data.token));
  }

  requestAccountRecovery(type: string, value: string): Observable<string> {
    const data = { };
    data[type] = value;

    return this.api.post<{ result: string }>(this.apiUrl('password-recovery/request'), data).pipe(
      map(response => response.result)
    );
  }

  validateAccountRecoveryToken(data: { token: string, email: string }): Observable<string> {
    return this.api.post<{ token: string }>(this.apiUrl('password-recovery/validate'), data).pipe(
      map(() => data.token)
    );
  }

  resetPassword(data: { token: string, newPassword: string }): Observable<void> {
    return this.api.post(this.apiUrl('password-recovery/reset'), data);
  }

  impersonateUser(username: string): Observable<string> {
    return this.api.post<{ token: string }>(this.apiUrl('impersonate-user'), { username }).pipe(
      map(response => response.token)
    );
  }

  switchToPreviousUser(): Observable<string> {
    return this.api.post<{ token: string }>(this.apiUrl('switch-to-previous-user')).pipe(
      map(response => response.token)
    );
  }

  // getUserPermissions(typeId: number): Observable<UserPreferences> {
  //   return this.api.get<UserPreferences>(this.apiUrl('user-preferences'), { typeId }).pipe(
  //     first()
  //   );
  // }

  getMenus(userID: number): Observable<MenuItem> {
    return this.api.get<MenuItem>(this.apiUrl('identity-user/get-usermenu'), { userID }).pipe(
      first()
    );
  }

  getSecondAuthMethod(email: string): Observable<{
    secondAuthenticationMethod: SecondAuthMethod,
    userId: string,
    allowsEmailAsMFA: boolean,
    lastLoginAt: Date
  }> {
    const encodedEmail = encodeURIComponent(email);
    return this.api.get<any>(this.apiUrl(`get-second-auth-method?username=${encodedEmail}`)).pipe(
      map(response => response.secondAuthenticationMethod)
    );
  }

  private apiUrl(path: string): string {
    return this.config.identityUrl + path;
  }

}
