import { Injectable } from '@angular/core';
import { AuthTokenPayload } from 'src/app/core/models/auth-token-payload';
import { User } from 'src/app/core/models/user';
import { select, Store } from '@ngrx/store';
import { State } from '../../root-store/root-state';
import { selectSessionOnNewAuthToken, selectSessionIsLoggedIn } from '../../root-store/session/session.selectors';
import { skip, switchMap } from 'rxjs/operators';
import { clearAuthCookie, clearSessionData, refreshAuthToken } from '../../root-store/session/session.actions';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError, timer } from 'rxjs';
import { SessionState } from '../../root-store/session/session.state';
import * as moment from 'moment';
import { UserTypes } from '../models/user-types';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private jwtHelper = new JwtHelperService();

  constructor(
    private store: Store<State>,
    private dialog: MatDialog
  ) {
    this.trackLoginStatus();

    // Here we are listening for a new token.
    this.store.pipe(selectSessionOnNewAuthToken)
      .pipe(switchMap(session => this.startRefreshTimer(session)))
      .subscribe(
        () => this.store.dispatch(refreshAuthToken()),
        () => this.store.dispatch(clearSessionData())
      );
  }

  getUserData(authToken: string): User {
    if(!authToken || authToken === '') {
      return null;
    }

    const tokenPayload = this.decodeToken(authToken);
    const { sub, userType, email, previousUserToken } = tokenPayload;

    return {
      username: sub,
      type: userType,
      email,
      typeId: this.getUserTypeId(tokenPayload),
      previousUser: previousUserToken || null,
      userId: tokenPayload?.userId,
    };
  }

  private getUserTypeId(tokenPayload: AuthTokenPayload): number {
    const {
      userType,
      dealerId,
      agentId,
      fincoNumber,
      dealerGroupId,
      corpGroupId,
      agentPackId,
      dealerPassThroughId,
      lenderPassThroughId,
      integrationPartnerPackId
    } = tokenPayload;

    switch (userType) {
      case UserTypes.Agent:
      case UserTypes.SubAgent:
        return agentId ? parseInt(agentId, 0) : null;
      case UserTypes.Dealer:
      case UserTypes.DealerEmp:
        return dealerId ? parseInt(dealerId, 0) : null;
      case UserTypes.Lender:
      case UserTypes.LenderEmp:
        return fincoNumber ? parseInt(fincoNumber, 0) : null;
      case UserTypes.DealerGroup:
      case UserTypes.DealerGroupEmp:
        return dealerGroupId ? parseInt(dealerGroupId, 0) : null;
      case UserTypes.CorpGroup:
      case UserTypes.CorpGroupEmp:
        return corpGroupId ? parseInt(corpGroupId, 0) : null;
      case UserTypes.AgentPack:
        return agentPackId ? parseInt(agentPackId, 0) : null;
      case UserTypes.DealerPassThrough:
        return dealerPassThroughId ? parseInt(dealerPassThroughId, 0) : null;
      case UserTypes.LenderPassThrough:
        return lenderPassThroughId ? parseInt(lenderPassThroughId, 0) : null;
      case UserTypes.IntegrationPartnerPack:
        return integrationPartnerPackId ? parseInt(integrationPartnerPackId, 0) : null;
      case UserTypes.AULAdmin:
      default:
        return null;
    }
  }

  private trackLoginStatus(): void {
    this.store
      .pipe(
        select(selectSessionIsLoggedIn),
        skip(1)
      )
      .subscribe(isLoggedIn => {
        if (!isLoggedIn) {
          this.dialog.closeAll();
          this.store.dispatch(clearAuthCookie());
        }
      });
  }

  private decodeToken(token: string): AuthTokenPayload {
    return this.jwtHelper.decodeToken(token);
  }

  getMillisecondsUntilRefresh(expiration: number): number {
    // After 30 minutes, we call an API service to refresh the token
    const millisecondsUntilRefresh = (expiration - 600 - moment.utc().unix()) * 1000;
    return millisecondsUntilRefresh <= 2147483647 ? millisecondsUntilRefresh : 2147483647;
  }

  private startRefreshTimer(session: SessionState): Observable<number> {
    let refreshTime = this.getMillisecondsUntilRefresh(this.decodeToken(session.authToken).exp);

    if(refreshTime <= -600000) {
      return throwError(true);
    }

    if(session.pickup || refreshTime <= 0) {
      refreshTime = 1;
    }

    return timer(refreshTime);
  }
}
