import { Action, createReducer, on } from '@ngrx/store';
import { signAsDealerInitialState, SignAsDealerState } from './sign-as-dealer.state';
import { selectDealer, setDealerList, unselectDealer } from './sign-as-dealer.actions';

const reducer = createReducer(
  signAsDealerInitialState,
  on(setDealerList, (state, { list }) => ({
    ...state, list
  })),
  on(selectDealer, (state, { selected }) => ({
    ...state, selected
  })),
  on(unselectDealer, state => ({
    ...state, selected: null
  }))
);

export function signAsDealerReducer(state: SignAsDealerState | undefined, action: Action) {
  return reducer(state, action);
}
