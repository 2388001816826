import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { UserTypes } from '../models/user-types';
import { SessionService } from './session.service';
import { select, Store } from '@ngrx/store';
import { State } from '../../root-store/root-state';
import { selectSessionUser } from '../../root-store/session/session.selectors';
import { selectDealer, unselectDealer } from '../../root-store/sign-as-dealer/sign-as-dealer.actions';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { selectSelectedDealer } from '../../root-store/sign-as-dealer/sign-as-dealer.selectors';
import { selectMenuPreferencesData } from '../../root-store/preferences/preferences.selectors';
import { MenuItem } from 'src/app/secure/models/menu-item';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  current: User;
  previous: User;
  isLegacyImpersonation = false;
  isImpersonating = false;
  impersonateChanges: Subject<{ legacy: boolean }> = new Subject<{ legacy: boolean }>();
  pagesPermissions: BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>(null);
  dataSource = new MatTreeNestedDataSource<MenuItem>();
  urlChecked: boolean = false;
  titles: string;


  private typeGroups: { [type: string]: string[] } = {
    dealer: [UserTypes.Dealer, UserTypes.DealerEmp],
    agent: [UserTypes.Agent, UserTypes.SubAgent],
    lender: [UserTypes.Lender, UserTypes.LenderEmp],
    admin: [UserTypes.AULAdmin],
    dealerGroup: [UserTypes.DealerGroup],
    agentAgency: [UserTypes.AgentCorp, UserTypes.AgentGroup]
  };

  constructor(
    private store: Store<State>,
    private session: SessionService
  ) {
    this.store.pipe(select(selectSessionUser)).subscribe(user => {
      this.handleLegacyImpersonation(user);
      this.setUserData(user);

      if (user) {
        this.impersonateChanges.next({ legacy: false });
      }
    });

    this.store.pipe(select(selectMenuPreferencesData))
      .subscribe(menuPreferences => {
        this.pagesPermissions.next(menuPreferences);
      });

    this.store.pipe(select(selectSelectedDealer))
      .pipe(switchMap(dealer => timer().pipe(map(() => dealer))))
      .subscribe(dealer => this.impersonateChanges.next({ legacy: !!dealer && !this.isDealer(this.current) }));

    this.impersonateChanges.subscribe(state => {
      this.setImpersonatingStatus(state.legacy);
    });
  }

  isAgent(user: User): boolean {
    return !!user && this.typeGroups.agent.indexOf(user.type) !== -1;
  }

  isAgentAgency(user: User): boolean {
    return !!user && this.typeGroups.agentAgency.indexOf(user.type) !== -1;
  }

  isDealer(user: User): boolean {
    return !!user && this.typeGroups.dealer.indexOf(user.type) !== -1;
  }

  isAdmin(user: User): boolean {
    return !!user && this.typeGroups.admin.indexOf(user.type) !== -1;
  }

  isDealerGroup(user: User): boolean {
    return !!user && this.typeGroups.dealerGroup.indexOf(user.type) !== -1;
  }

  getCurrentUserType(userType = null): string {
    const currentUserType = userType ?? this.current.type;
    const userTypesDic = {
      [UserTypes.AgentCorp]: 'Agent Corp',
      [UserTypes.AgentGroup]: 'Agent Group',
    };

    return userTypesDic[currentUserType] ?? currentUserType;
  }

  getUserTypeGroup(user: User): string {
    if (!user) {
      return null;
    }

    switch (user.type) {
      case UserTypes.Agent:
      case UserTypes.SubAgent:
        return 'agent';
      case UserTypes.Dealer:
        return 'dealer';
      case UserTypes.DealerEmp:
        return 'dealerEmp';
      case UserTypes.Lender:
      case UserTypes.LenderEmp:
        return 'lender';
      case UserTypes.DealerGroup:
      case UserTypes.DealerGroupEmp:
        return 'dealerGroup';
      case UserTypes.CorpGroup:
        return 'corpGroup';
      case UserTypes.CorpGroupEmp:
        return 'corpGroupEmp';
      case UserTypes.AULAdmin:
        return 'admin';
      case UserTypes.AgentCorp:
      case UserTypes.AgentGroup:
        return 'agentAgency';
    }
  }

  private setUserData(user: User): void {
    this.current = user;
    this.previous = user ? this.session.getUserData(user.previousUser) : null;
  }

  setImpersonatingStatus(isLegacyImpersonation: boolean): void {
    this.isLegacyImpersonation = isLegacyImpersonation;
    this.isImpersonating =
      this.isLegacyImpersonation
      || !!(this.previous && (this.isAdmin(this.previous)
      || (this.isAgent(this.previous) || this.isAgentAgency(this.previous)
      || (this.isDealerGroup(this.previous)) && !this.isAdmin(this.current))));
  }

  private handleLegacyImpersonation(user: User): void {
    if (this.isDealer(user)) {
      this.store.dispatch(selectDealer({ selected: { name: user.username, id: user.typeId, code: null } }));
    } else {
      this.store.dispatch(unselectDealer());
    }
  }


  checkPermission(url: string, pagesPermissions?: MenuItem): boolean {
    this.urlChecked = false;
    if (url === 'edit-contract') {
      return this.urlChecked = true;
    }
    else {
      if (pagesPermissions) {
        this.dataSource.data = pagesPermissions['menus'];
        return this.getAllUrls(this.dataSource.data, url);
      }
      else {
        this.dataSource.data = this.pagesPermissions.value['menus'];
        return this.getAllUrls(this.dataSource.data, url);
      }
    }

  }

  private getAllUrls(menu: MenuItem[], url: string): boolean {
    menu.forEach(item => {
      if (item.url.includes(url)) {
        return this.urlChecked = true;
      }
      if (item.children && item.children.length) {
        this.getAllUrls(item.children, url);
      }
    });
    return this.urlChecked;
  }

  checkPageTitle(Url: string, pagesPermissions?: MenuItem) {
    if (pagesPermissions) {
      this.dataSource.data = pagesPermissions['menus'];
      return this.fetchTitles(this.dataSource.data, Url);
    }
  }
  private fetchTitles(menu: MenuItem[], Url: string, children = false) {
    if (Url == '') {
      return this.titles = menu[0].label;
    } else {
      menu.forEach(item => {
        if (item.url.includes(Url) == true) {

          return this.titles = item.label;
        }
        if (item.children && item.children.length) {
          this.fetchTitles(item.children, Url, true);
        }
      });
    }
    return this.titles;
  }
}
