import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../root-state';
import { PreferencesState, MenuPreferencesState } from './preferences.state';

export const selectPreferences = createFeatureSelector<State, PreferencesState>('preferences');
export const selectMenuPreferences = createFeatureSelector<State, MenuPreferencesState>('menuPreferences');

export const selectPreferencesData = createSelector(
  selectPreferences,
  state => state.preferences
);

export const selectAch = createSelector(
  selectPreferences,
  state => state.preferences.ach
);

export const selectMenuPreferencesData = createSelector(
  selectMenuPreferences,
  state => state.menuPreferences
);


