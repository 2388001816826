import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  developmentMode: boolean;
  showCustomerPortalLink: boolean;

  identityUrl: string;
  dealerUrl: string;
  quoteAndSalesUrl: string;
  aulCorpUrl: string;
  revertUrl: string;
  legacyLoginUrl: string;
  ratesUrl: string;
  gapUrl: string;
  sppUrl: string;
  ullUrl: string;
  reportsUrl: string;
  customerPortalUrl: string;
  payeePacksUrl: string;

  b2c: {
    tenantId: string,
    clientId: string,
    tenantName: string,
    domain: string,
    redirectUrl: string,
    postLogoutRedirectUri: string,
    policies: {
      names: {
        smsOrPhoneCall: string,
        smsOrPhoneCallConditional: string,
        emailOnly: string,
        emailOnlyConditional: string,
        sfa: string
      }
    }
  };

  constructor() {
    this.getEnvironmentVariables();
  }

  private getEnvironmentVariables(): void {
    this.developmentMode = environment.dev;
    this.identityUrl = environment.identityUrl;
    this.dealerUrl = environment.dealerUrl;
    this.quoteAndSalesUrl = environment.quoteAndSalesUrl;
    this.aulCorpUrl = environment.aulCorpUrl;
    this.revertUrl = environment.revertUrl;
    this.legacyLoginUrl = environment.legacyLoginUrl;
    this.ratesUrl = environment.ratesUrl;
    this.gapUrl = environment.gapUrl;
    this.sppUrl = environment.sppUrl;
    this.ullUrl = environment.ullUrl;
    this.reportsUrl = environment.reportsUrl;
    this.showCustomerPortalLink = environment.showCustomerPortalLink;
    this.customerPortalUrl = environment.customerPortalUrl;
    this.payeePacksUrl = environment.dealerUrl;
    this.b2c = environment.b2c;
  }
}
