import { createAction, props } from '@ngrx/store';
import { UserPreferences } from '../../secure/models/user-preferences';
import { MenuItem } from '../../secure/models/menu-item';

export const getUserPreferences = createAction(
  '[Preferences] Get user Preferences',
  props<{ userId: number }>()
);

export const setUserPreferences = createAction(
  '[Preferences] Set user Preferences',
  props<UserPreferences>()
);

export const clearUserPreferences = createAction(
  '[Preferences] Clear user Preferences'
);

export const getUserMenus = createAction(
  '[MenuPreferences] Get user MenuPreferences',
  props<{ userId: number }>()
);
export const setUserMenu = createAction(
  '[MenuPreferences] Set user MenuPreferences',
  props<MenuItem>()
);
