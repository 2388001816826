import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { windowProvider, windowToken } from './core/services/window';
import { documentProvider, documentToken } from './core/services/document';
import { SharedModule } from './shared/shared.module';
import './core/extensions/string-extensions';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    CommonModule,
    SharedModule
  ],
  providers: [
    { provide: windowToken, useFactory: windowProvider },
    { provide: documentToken, useFactory: documentProvider }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
