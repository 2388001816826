import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../root-state';
import { SignAsDealerState } from './sign-as-dealer.state';

export const selectSignAsDealer = createFeatureSelector<State, SignAsDealerState>('signAsDealer');

export const selectDealerList = createSelector(
  selectSignAsDealer,
  state => state.list
);

export const selectSelectedDealer = createSelector(
  selectSignAsDealer,
  state => state.selected
);

