import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '../../secure/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerHttpInterceptorService implements HttpInterceptor{
  count = 0;

  constructor(private spinnerService: SpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.toggleSpinner(true);
    this.count++;
    return next.handle(req)
      .pipe ( tap(
         event => { },
         error => { }
        ), finalize(() => {
          this.count--;
          if ( this.count === 0 ) {
            this.spinnerService.toggleSpinner(false);
          }
        })
      );
  }
}
