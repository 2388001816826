import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { preferencesReducer, preferencesMenuReducer} from './preferences.reducer';
import { PreferencesEffects } from './preferences.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('preferences', preferencesReducer),
    StoreModule.forFeature('menuPreferences', preferencesMenuReducer),
    EffectsModule.forFeature([ PreferencesEffects ])
  ],
  providers: [
    PreferencesEffects
  ]
})
export class PreferencesModule { }
