import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TextInputComponent } from './components/text-input/text-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArrowLinkComponent } from './components/arrow-link/arrow-link.component';
import { RouterModule } from '@angular/router';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';
import { MessageScreenComponent } from './components/message-screen/message-screen.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { MatMomentDateModule, MomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OptionCardComponent } from './components/option-card/option-card.component';
import { SelectComponent } from './components/select/select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { ContactMessageComponent } from './components/contact-message/contact-message.component';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { SuccessScreenComponent } from './components/success-screen/success-screen';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { UpsellWarningMessageComponent } from '../secure/components/upsell-warning-message/upsell-warning-message.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DealerGuardComponent } from './components/dealer-guard/dealer-guard.component';
import { DealerSelectorComponent } from './components/dealer-selector/dealer-selector.component';
import { TabComponent } from './components/tab/tab.component';
import { NGX_MAT_DATE_FORMATS, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { BannerComponent } from './components/banner/banner.component';
import { TableComponent } from './components/table/table.component';
import { TableModule } from 'primeng/table';
import { ChartsModule } from 'ng2-charts';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { TableDatepickerComponent } from './components/table-datepicker/table-datepicker.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TableCellsComponent } from './components/table-cells/table-cells.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableFooterComponent } from './components/table-footer/table-footer.component';
import { TableToolbarComponent } from './components/table-toolbar/table-toolbar.component';
import { DatepickerFiltersComponent } from './components/datepicker-filters/datepicker-filters.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerHttpInterceptorService } from '../core/services/spinner-http-interceptor.service';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AccordionModule } from 'primeng/accordion';
import { CompleteProfileModalComponent } from './components/complete-profile-modal/complete-profile-modal.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { CustomMultiselectComponent } from './components/custom-multiselect/custom-multiselect.component';

@NgModule({
  declarations: [
    FormErrorComponent,
    TextInputComponent,
    ArrowLinkComponent,
    PasswordRulesComponent,
    MessageScreenComponent,
    PasswordStrengthComponent,
    ModalContainerComponent,
    AutocompleteComponent,
    DynamicPipe,
    CheckboxComponent,
    OptionCardComponent,
    CheckboxComponent,
    SelectComponent,
    SlideToggleComponent,
    ScrollToTopComponent,
    ContactMessageComponent,
    TypeaheadComponent,
    SuccessScreenComponent,
    TabComponent,
    WarningMessageComponent,
    ConfirmationModalComponent,
    RadioButtonComponent,
    UpsellWarningMessageComponent,
    DealerGuardComponent,
    DealerSelectorComponent,
    BannerComponent,
    TableComponent,
    TableDatepickerComponent,
    TableCellsComponent,
    NotificationSettingsComponent,
    TableHeaderComponent,
    TableFooterComponent,
    TableToolbarComponent,
    DatepickerFiltersComponent,
    AccordionComponent,
    MultiselectComponent,
    CompleteProfileModalComponent,
    ToggleComponent,
    CustomMultiselectComponent
  ],
    exports: [
      FormErrorComponent,
      TextInputComponent,
      ArrowLinkComponent,
      PasswordRulesComponent,
      MessageScreenComponent,
      PasswordStrengthComponent,
      ModalContainerComponent,
      AutocompleteComponent,
      DynamicPipe,
      CheckboxComponent,
      OptionCardComponent,
      CheckboxComponent,
      SelectComponent,
      SlideToggleComponent,
      ScrollToTopComponent,
      ContactMessageComponent,
      TypeaheadComponent,
      SuccessScreenComponent,
      BannerComponent,
      WarningMessageComponent,
      ConfirmationModalComponent,
      RadioButtonComponent,
      UpsellWarningMessageComponent,
      NgxSpinnerModule,
      DealerGuardComponent,
      DealerSelectorComponent,
      TabComponent,
      TableComponent,
      NotificationSettingsComponent,
      ChartsModule,
      DatepickerFiltersComponent,
      AccordionComponent,
      MultiselectComponent,
      ToggleComponent,
      CustomMultiselectComponent
    ],
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ScrollingModule,
    MatTooltipModule,
    MomentDateModule,
    MatCheckboxModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    MatRadioModule,
    NgxSpinnerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    TableModule,
    ButtonModule,
    ChartsModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    MatInputModule,
    MatFormFieldModule,
    MatExpansionModule,
    AccordionModule
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'MM/DD/YYYY'
        },
        display: {
          dateInput: 'MM/DD/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'l LT'
        },
        display: {
          dateInput: 'l LT',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerHttpInterceptorService,
      multi: true,
    }
  ]

})
export class SharedModule { }
