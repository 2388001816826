import { createAction, props } from '@ngrx/store';
import { PayeePacks } from 'src/app/secure/models/payee-packs';

export const getPayeePacksData = createAction(
  '[PayeePacks] Get PayeePack Data');

export const setPayeePacksData = createAction(
  '[PayeePacks] Set PayeePack Data',
  props<PayeePacks | null>()
);

export const unselectPayeePacksData = createAction(
  '[PayeePacks] Unselect PayeePacks'
);
