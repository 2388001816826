export enum UserTypes {
    Agent = 'Agent',
    SubAgent = 'SubAgent',
    Dealer = 'Dealer',
    DealerEmp = 'DealerEmp',
    AULAdmin = 'AULadmin',
    Lender = 'Lender',
    LenderEmp = 'LenderEmp',
    AAAdmin = 'AAA-Admin',
    DealerGroup = 'DealerGroup',
    DealerGroupEmp = 'DealerGrpEmp',
    CorpGroup = 'CorpGroup',
    CorpGroupEmp = 'CorpGroupEmp',
    SentinelLender = 'SentinelLender',
    SentinelLenderEmp = 'SentinelLenderEmp',
    AgentPack = 'Agent Pack',
    IntegrationPartnerPack = 'Integration Partner Pack',
    DealerPassThrough = 'Dealer Pass Through',
    LenderPassThrough = 'Lender Pass Through',
    AgentGroup = 'AgentGroup',
    AgentCorp = 'AgentCorp',
}
