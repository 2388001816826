import { Component, HostListener, Input, OnInit } from '@angular/core';
import { WindowService } from '../../../core/services/window.service';

@Component({
  selector: 'adl-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {
  @Input() startPosition: number;
  showScrollToTop: boolean;

  @HostListener('window:scroll')
  private checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.showScrollToTop = scrollPosition >= this.startPosition;
  }

  constructor(
    private windowService: WindowService
  ) { }

  ngOnInit() {
  }

  scrollToTop() {
    this.windowService.scrollTo(0);
  }

}
