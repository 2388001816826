import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ConfigService } from '../../core/services/config.service';
import { Observable } from 'rxjs';
import { ZipInformation } from '../models/zip-information';
import { UpsellInformation } from '../models/upsell-information';
import { UpsellInformationParams } from '../models/upsell-information-params';
import { GetContractNumberParams } from '../models/get-contract-number-params';
import { ContractPdf } from '../models/contract-pdf';
import { PrintContractParams } from '../models/print-contract-params';
import { map, first } from 'rxjs/operators';
import { PrintQuoteParams } from '../models/print-quote-params';
import { QuotePdf } from '../models/quote-pdf';
import { EditContract } from '../models/edit-contract';
import { ContractUpdate } from '../models/contract-update';
import { ContractUpdateParams } from '../models/contract-update-params';
import { DownloadContractParams } from '../models/download-contract-params';
import { HttpResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {
  edit: { contractNumber: string, dealerId: number, editDisabled?: boolean } = null;

  constructor(private api: ApiService, private config: ConfigService) { }

  getZipInformation(zip: number): Observable<ZipInformation> {
    return this.api.get<ZipInformation>( this.apiUrl('zip-information'), { zip } );
  }

  getUpsellInformation(params: UpsellInformationParams): Observable<UpsellInformation> {
    return this.api.post<UpsellInformation>(this.apiUrl('get-info-for-upsell'),  params );
  }

  getContractNumber(getContractNumberParams: GetContractNumberParams): Observable<string> {
    if (getContractNumberParams.vin) {
      return this.api.post<string>(this.apiUrl('generate-number'), getContractNumberParams, { responseType: 'text' });
    } else {
      return this.api.post<string>(this.apiUrl('generate-number-novin'), getContractNumberParams, { responseType: 'text' });
    }
  }

  print(params: PrintContractParams): Observable<ContractPdf> {
    return this.api.post<{ result: ContractPdf }>(this.apiUrl('print-contract'), params).pipe(
      map(response => response.result)
    );
  }

  downloadContract(params: DownloadContractParams): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders().set('Accept', 'application/pdf');

    const httpOptions = {
      headers,
      observe: 'response',
      responseType: 'blob' as 'json'
    };

    return this.api.post<HttpResponse<Blob>>(
      this.apiUrl('download-contract'),
      params,
      httpOptions
    ).pipe(
      first()
    );
  }

  printQuote(params: PrintQuoteParams): Observable<QuotePdf> {
    return this.api.post<QuotePdf>(this.apiUrl('print-quote'), params);
  }

  updateContract(params: ContractUpdateParams): Observable<ContractUpdate> {
    return this.api.post<ContractUpdate>(this.apiUrl('update'), params);
  }

  getContract(cert: string, dealerRoleId: number): Observable<EditContract> {
    return this.api.get<EditContract>(this.apiUrl('contract'), { cert, dealerRoleId });
  }

  private apiUrl(path: string): string {
    return this.config.ratesUrl + 'contracts/' + path;
  }
}
