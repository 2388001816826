import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthenticatedGuard } from './core/guards/authenticated-guard';
import { MsalGuard } from '@azure/msal-angular';

const routes = [
  { path: 'portal', canActivate: [ AuthenticatedGuard, MsalGuard ],  loadChildren: () => import('./secure/secure.module').then(m => m.SecureModule)},
  { path: '', loadChildren: () => import('./public/public.module').then(m => m.PublicModule)},
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,
      {
        useHash: false,
        scrollPositionRestoration: 'enabled',
        initialNavigation: true
      })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
