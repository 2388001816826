export const lienholderTypes = {
  spp: {
    id: 30,
    name: 'SPP',
    type: 'b-list'
  },
  universallendersllc: {
    id: 3592,
    name: 'Universal Lenders LLC',
    type: 'b-list'
  },
  servicepaymentplanb:{
    id: 3975,
    name: 'Service Payment Plan - Franchise Program',
    type: 'b-list'
  }
};
