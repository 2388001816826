<ng-container *ngIf="(quoteSalesService.legacyLoginUrl | async) as url">
  <iframe
    *ngIf="url"
      #authIFrame
      id="quoteSalesAuth"
      height="0"
      width="0"
      tabindex="-1"
      hidden
      frameborder="0"
      scrolling="no"
      [src]="url"
  ></iframe>
</ng-container>
<adl-scroll-to-top [ngClass]="{ 'embedded' : isPageEmbedded, 'noEmbedded' : !isPageEmbedded }" [startPosition]="100"></adl-scroll-to-top>
<router-outlet></router-outlet>
