import { Action, createReducer, on } from '@ngrx/store';
import { preferencesInitialState, PreferencesState, MenupreferencesInitialState, MenuPreferencesState } from './preferences.state';
import { clearUserPreferences, setUserPreferences, setUserMenu } from './preferences.actions';

const reducer = createReducer(
  preferencesInitialState,
  on(setUserPreferences, (state, preferences) => ({ ...state, preferences })),
  on(clearUserPreferences, () => preferencesInitialState)
);

const Menureducer = createReducer(
  MenupreferencesInitialState,
  on(setUserMenu, (state, menuPreferences) => ({ ...state, menuPreferences })),
  on(clearUserPreferences, () => MenupreferencesInitialState)
);


export function preferencesReducer(state: PreferencesState | undefined, action: Action) {
  return reducer(state, action);
}

export function preferencesMenuReducer(state: MenuPreferencesState | undefined, action: Action) {
  return Menureducer(state, action);
}

