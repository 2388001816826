import { Injectable } from '@angular/core';
import { SessionService } from '../../core/services/session.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mergeMap, tap, switchMap, catchError, concatMap } from 'rxjs/operators';
import {
  clearAuthCookie,
  clearSessionData,
  getUserData,
  logoutUser,
  readAuthCookie,
  redirectToLogin,
  redirectToPortal,
  redirectToUrl,
  refreshAuthToken,
  setSessionData,
  setAuthCookie,
  logoutUserB2C
} from './session.actions';
import { forkJoin, from, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { unselectDealer } from '../sign-as-dealer/sign-as-dealer.actions';
import { QuoteSalesService } from '../../core/services/quote-sales.service';
import { clearUserPreferences, getUserPreferences, getUserMenus } from '../preferences/preferences.actions';
import { MsalService } from '@azure/msal-angular';
import { env } from 'process';
import { environment } from 'src/environments/environment';

@Injectable()
export class SessionEffects {

  constructor(
    private actions: Actions,
    private session: SessionService,
    private authService: AuthService,
    private msalAuthServce: MsalService,
    private cookieService: CookieService,
    private router: Router,
    private quoteSalesService: QuoteSalesService
  ) { }

  readAuthCookie = createEffect(() => this.actions.pipe(
    ofType(readAuthCookie),
    mergeMap(() => {
      const authToken = this.cookieService.get('Auth');
      if(authToken === '') {
        return of(clearSessionData());
      } else {
        return of(getUserData({ authToken, pickup: true }));
      }
    })
  ));

  setAuthCookie = createEffect(() => this.actions.pipe(
    ofType(setAuthCookie),
    exhaustMap(action => {
      this.cookieService.set('Auth', action.authToken, 0, '/', undefined, true);
      return of(getUserData({ authToken: action.authToken, pickup: false }));
    })
  ));

  clearAuthCookie = createEffect(() => this.actions.pipe(
    ofType(clearAuthCookie),
    switchMap(() => {
      this.cookieService.delete('Auth', '/');
      this.cookieService.delete('msal:authority');
      this.quoteSalesService.clearAuthCookie();

      return ([
        redirectToLogin(),
        clearUserPreferences()
      ]);
    })
  ));

  getUserData = createEffect(() => this.actions.pipe(
    ofType(getUserData),
    switchMap(action => {
      const user = this.session.getUserData(action.authToken);
      return ([
        setSessionData({ user, authToken: action.authToken, pickup: action.pickup }),
        getUserMenus({ userId : user.typeId })
      ]);
    })
  ));

  logoutUser = createEffect(() => this.actions.pipe(
    ofType(logoutUser),
    mergeMap(() => {
      return this.authService.logout().pipe(
        switchMap(() => [
          unselectDealer(),
          clearSessionData(),
          clearUserPreferences(),
        ])
      );
    })
  ));


  logoutUserB2C = createEffect(() => this.actions.pipe(
    ofType(logoutUserB2C),
    mergeMap(() => from(this.msalAuthServce.instance.logoutRedirect({ })))
  ), { dispatch: false });

  // Here is the logic that refresh the token
  // SetAuthCookie calls getUserData that call getUserMenus
  refreshAuthToken = createEffect(() => this.actions.pipe(
    ofType(refreshAuthToken),
    mergeMap(() => {
      return this.authService.refreshToken().pipe(
        map(authToken => setAuthCookie({ authToken })),
        catchError(() => from([
          unselectDealer(),
          clearSessionData()
        ]))
      );
    })
  ));

  redirectToLogin = createEffect(() => this.actions.pipe(
    ofType(redirectToLogin),
    tap(() => {
      this.router.navigate(['/login']);
    })
  ), { dispatch: false });

  redirectToPortal = createEffect(() => this.actions.pipe(
    ofType(redirectToPortal),
    tap(() => {
      this.router.navigate(['/portal']);
    })
  ), { dispatch: false });

  redirectToUrl = createEffect(() => this.actions.pipe(
    ofType(redirectToUrl),
    tap(() => {
      // We take an absolute route path to navigate with query params
      this.router.navigateByUrl(this.authService.urlToRedirect);
    })
  ), { dispatch: false });

}
