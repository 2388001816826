import { Injectable, NgZone } from '@angular/core';
import { MenuItem } from '../../secure/models/menu-item';
import * as _ from 'lodash';
import { ConfigService } from './config.service';
import {
  selectSessionOnNewAuthToken,
  selectSessionToken,
} from '../../root-store/session/session.selectors';
import { Store, select } from '@ngrx/store';
import { State } from '../../root-store/root-state';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, Data } from '@angular/router';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { NavigationService } from './navigation.service';
import { redirectToPortal, setAuthCookie } from '../../root-store/session/session.actions';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { delayWhen, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WindowService } from './window.service';
import { RatesUIService } from '../../secure/services/rates-ui.service';
import { ContractsService } from '../../secure/services/contracts.service';
import { SpinnerService } from '../../secure/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class QuoteSalesService {
  _authEvents: BehaviorSubject<string> = new BehaviorSubject<string>('CHECKING');
  authEvents = this._authEvents.asObservable();
  _legacyLoginUrl: BehaviorSubject<SafeResourceUrl> = new BehaviorSubject(null);
  legacyLoginUrl = this._legacyLoginUrl.asObservable();
  _revertPortalUrl: BehaviorSubject<string> = new BehaviorSubject(null);
  revertPortalUrl = this._revertPortalUrl.asObservable();
  authIFrame: any;
  pageIFrame: any;
  routeMappings: { legacyUrl: string, url: string }[] = [];
  routeData: Data;
  _embedded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  embedded$: Observable<boolean> = this._embedded.asObservable();

  constructor(
    private config: ConfigService,
    private store: Store<State>,
    private sanitizer: DomSanitizer,
    private zone: NgZone,
    private router: Router,
    private navigationService: NavigationService,
    private authService: AuthService,
    private user: UserService,
    private windowService: WindowService,
    private spinnerService: SpinnerService,
    private ratesUIService: RatesUIService,
    private contractsService: ContractsService
  ) {
    this.setLegacyUrlsOnNewLogin();
    this.trackAuthenticationEvents();

    this.navigationService.ready.subscribe(() => {
      this.navigationService.routeData.subscribe(routeData => this.routeData = routeData);
    });

    this.user.impersonateChanges
      .pipe(filter(state => !!state.legacy))
      .subscribe(() => this.sendMessage('adl:reload', document.querySelector('#quoteAndSales')));
  }

  private setLegacyUrlsOnNewLogin(): void {
    this.authEvents
      .pipe(
        filter(status => status === 'INIT_LOGIN'),
        withLatestFrom(this.store.pipe(select(selectSessionToken))),
        map(value => value[1])
      )
      .subscribe(authToken => {
        this._legacyLoginUrl.next(this.getSanitizedUrl(`/${this.config.legacyLoginUrl}?token=${authToken}`));
        this._revertPortalUrl.next(`${this.config.revertUrl}/[OLD_PORTAL_PATH]?token=${authToken}`);
        this.devModeLogin();
      });
  }

  private trackAuthenticationEvents(): void {
    this.store
      .pipe(
        selectSessionOnNewAuthToken,
        filter(session => !session.pickup)
      )
      .subscribe(() => this._authEvents.next('INIT_LOGIN'));
  }

  setAuthIFrame(iFrame: any): void {
    this.authIFrame = iFrame;
  }

  setPageIFrame(iFrame: any): void {
    this.pageIFrame = iFrame;
  }

  impersonateUser(username?: string): void {
    const action = username ? this.authService.impersonateUser(username) : this.authService.switchToPreviousUser();

    action.subscribe(authToken => {
      this.store.dispatch(setAuthCookie({ authToken }));
      this.store.dispatch(redirectToPortal());
    });
  }

  clearAuthCookie(): void {
    if (!this.authIFrame) {
      return;
    }

    this.sendMessage('adl:logout', this.authIFrame);
    this._authEvents.next('INIT_LOGOUT');
  }

  setRouteMappings(menuItems: MenuItem[], menuType: string): void {
    const flat = [];

    _.cloneDeep(menuItems).forEach(menuItem => {
      this.getFlatMapOfMenuItem(menuItem, flat, menuType);
    });

    this.routeMappings = flat;
  }

  getUrlFromLegacy(legacyUrl: string): string {
    const result = this.routeMappings.find(mapping => mapping.legacyUrl === legacyUrl);
    return result ? result.url : null;
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.config.quoteAndSalesUrl}${url}`);
  }

  onMessage(event: MessageEvent): void {
    if (event.origin.indexOf(this.config.quoteAndSalesUrl) === -1) {
      return;
    }

    this.zone.run(() => {
      const { url, loggedIn, loggedOut, queryString, displayPdf, editContractNumber, dealerId, contractNumber } = event.data;

      if ((queryString && queryString.length === 1 && queryString[0] === '') || this.config.developmentMode) {
        this.spinnerService.toggleSpinner(false);
      }

      if (loggedIn) {
        timer().subscribe(() => this._authEvents.next('LOGGED_IN'));
      }

      if (loggedOut) {
        timer().subscribe(() => this._authEvents.next('LOGGED_OUT'));
      }

      // Remove this after search contract release
      if (contractNumber) {
        this.ratesUIService.contractNumber = contractNumber;
      }

      if (displayPdf) {
        timer().subscribe(() => this.windowService.open(`${this.config.quoteAndSalesUrl}/Pricing/DisplayPdf.aspx`, '_blank'));
      }

      if (editContractNumber || dealerId) {
        this.contractsService.edit = {
          contractNumber: editContractNumber,
          dealerId: parseInt(dealerId, 0)
        };
        timer(1000).subscribe(() => this.router.navigate(['/portal/contracts/edit-contract']));
        return;
      }

      if (url) {
        if (url.toLowerCase() === '/userimpersonated.aspx') {
          const usernameParam = queryString.find(param => param.indexOf('username=') === 0);

          if (usernameParam) {
            const username = usernameParam.replace('username=', '');
            this.impersonateUser(username);
            return;
          }
        }

        const newUrl = this.getUrlFromLegacy(url);
        const legacyUrl = this.routeData.legacyUrl;

        if (this.isLegacyUrlActive(legacyUrl, url)) {
          return;
        }

        if (newUrl) {
          this.router.navigate([newUrl]);
        }
      }
    });
  }

  sendMessage(message: any, iFrame: any): void {
    if (!iFrame) {
      return;
    }

    iFrame.contentWindow.postMessage(message, '*');
  }

  getRevertLink(path: string): Observable<string> {
    return this.revertPortalUrl.pipe(
      filter(url => !!url),
      map(url => url.replace('[OLD_PORTAL_PATH]', path))
    );
  }

  urlIsPricer(url: string): boolean {
    return url.toLowerCase() === '/portal/rate/rate-contract';
  }

  private getFlatMapOfMenuItem(menuItem: MenuItem, flat: any[], menuType: string): void {
    if (menuItem.legacyUrl) {
      const legacyUrl = typeof (menuItem.legacyUrl) === 'string' ? menuItem.legacyUrl : menuItem.legacyUrl[menuType];
      flat.push({ legacyUrl, url: menuItem.url });
    }

    if (menuItem.children) {
      menuItem.children.forEach(child => {
        this.getFlatMapOfMenuItem(child, flat, menuType);
      });
    }
  }

  private devModeLogin(): void {
    if (this.config.developmentMode) {
      this.authEvents
        .pipe(
          filter(event => event === 'INIT_LOGIN'),
          switchMap(() => timer())
        )
        .subscribe(() => this._authEvents.next('LOGGED_IN'));
    }
  }

  private isLegacyUrlActive(legacyUrl: string | Data, url: string): boolean {
    const menuType = this.user.getUserTypeGroup(this.user.current);
    return !!legacyUrl &&
      (
        (typeof (legacyUrl) === 'string' && legacyUrl.indexOf(url) !== -1) ||
        (typeof (legacyUrl) !== 'string' && legacyUrl[menuType] && legacyUrl[menuType].indexOf(url) !== -1)
      );
  }

  setEmbedded(isEmbedded: boolean): void {
    this._embedded.next(isEmbedded);
  }
}
