import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ConfigService } from '../../core/services/config.service';
import { GetDealerParams } from '../models/get-dealers-params';
import { DealerInfo } from '../models/dealer-info';
import { Observable, of } from 'rxjs';
import { Dealer } from '../models/dealer';
import { mapTo, first } from 'rxjs/operators';
import { AgentDealer } from '../models/AgentDealer';
import { SubAgent } from '../models/agent';
import { subagent_dealer_relation } from '../models/subagent-dealer-relations-request';
import { SignedInUser } from '../models/signedInUser';
import { DealerDealerGroupRelation } from '../models/dealer-dealer-group-relations-request';

@Injectable()
export class DealerService {
  constructor(private api: ApiService, private config: ConfigService) { }

  getAllForUser(params: GetDealerParams): Observable<DealerInfo[]> {
    return this.api.get<DealerInfo[]>(this.apiUrl('dealers'), params);
  }

  impersonate(dealerId: number, canImpersonate: boolean): Observable<boolean> {
    if (!canImpersonate) {
      return of(true);
    }
    return this.api.post<void>(this.apiUrl('impersonate'), { dealerId }).pipe(mapTo(true));
    }

  getDealer(dealerId: number): Observable<Dealer> {
    return this.api.get(this.apiUrl(`dealers/${dealerId}`));
  }

  private apiUrl(path: string): string {
    return this.config.dealerUrl + path;
  }

  getDealers(userId: string, accountRoleId: string, isAssigned: number): Observable<AgentDealer[]> {
    return this.api.get<AgentDealer[]>(
      this.apiUrl(`dealers/get-dealer-group-employee-dealer?accountRoleId=${accountRoleId}&userId=${userId}&isAssigned=${isAssigned}`)
    );
  }

  getSubagentDealers(userid: string, agentRoleIdentifier: string, isAssinged: number): Observable<AgentDealer[]> {
    return this.api.get<AgentDealer[]>(
      this.apiUrl(`dealers/get-agent-dealer?agentRoleIdentifier=${agentRoleIdentifier}&userId=${userid}&isAssinged=${isAssinged}`)
    );
  }
  getSubagent(): Observable<SubAgent[]> {
    return this.api.get<SubAgent[]>(this.apiUrl(`dealers/get-subagent-select`));
  }

  relationUpsert(relation: subagent_dealer_relation): Observable<any> {
    const UserID = relation.UserID;
    const Relations = relation.Relations;
    return this.api.post<any>(this.apiUrl('dealers/send-subagent-dealer-data'), { UserID, Relations }).pipe(first());
  }

  relationUpsertDealersToDealerGroup(relation: DealerDealerGroupRelation): Observable<any> {
    return this.api.
    post<any>(this.apiUrl('dealers/send-dealer-group-employee-dealer-data'),
    {
      UserID: relation.UserID,
      Relations: relation.Relations
    })
    .pipe(first());
  }

  getuserID(userName: string): Observable<SignedInUser> {
    return this.api.get(this.apiUrl(`dealers/get-user-id?userName=${userName}`));
  }

}

