import { createAction, props } from '@ngrx/store';
import { User } from '../../core/models/user';

export const readAuthCookie = createAction(
  '[Session] Read Auth Cookie'
);

export const setAuthCookie = createAction(
  '[Session] Set Auth Cookie',
  props<{ authToken: string }>()
);

export const getUserData = createAction(
  '[Session] Get User Data',
  props<{ authToken: string, pickup: boolean }>()
);

export const setSessionData = createAction(
  '[Session] Set Session Data',
  props<{ user: User, authToken: string, pickup: boolean }>()
);

export const refreshAuthToken = createAction(
  '[Session] Refresh Auth Token'
);

export const redirectToLogin = createAction(
  '[Session] Redirect To Login'
);

export const redirectToPortal = createAction(
  '[Session] Redirect To Portal'
);

export const redirectToUrl = createAction(
  '[Session] Redirect To URL'
);

export const logoutUser = createAction(
  '[Session] Logout'
);

export const logoutUserB2C = createAction(
  '[Session] Logout B2C'
);

export const clearSessionData = createAction(
  '[Session] Clear Session Data'
);

export const clearAuthCookie = createAction(
  '[Session] Clear Auth Cookie'
);
