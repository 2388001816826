import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { PassThroughService } from "src/app/secure/services/pass-through.service";
import { payeePacksReducer } from "./payee-packs.reducer";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('payeePacksSelected', payeePacksReducer)
  ],
  providers: [
    PassThroughService,
  ]
})
export class PayeePacksModule { }
