import { createAction, props } from '@ngrx/store';
import { DealerInfo } from 'src/app/secure/models/dealer-info';
import { GetDealerParams } from 'src/app/secure/models/get-dealers-params';
import { PassThroughInfo } from 'src/app/secure/models/pass-through-info';

export const getDealerList = createAction(
  '[Sign As Dealer] Get Dealer List',
  props<{ params: GetDealerParams  }>()
);

export const setDealerList = createAction(
  '[Sign As Dealer] Set Dealer List',
  props<{ list: DealerInfo[] }>()
);

export const selectDealer = createAction(
  '[Sign As Dealer] Select Dealer',
  props<{ selected: DealerInfo }>()
);

export const unselectDealer = createAction(
  '[Sign As Dealer] Unselect Dealer'
);

export const impersonate = createAction(
  '[Sign As Dealer] Impersonate Dealer',
  props<{ selected: DealerInfo, canImpersonate: boolean }>()
);

export const impersonatePassThrough = createAction(
  '[Sign As PassThrough] Impersonate PassThrough',
  props<{ selected: PassThroughInfo, canImpersonate: boolean, userType: string }>()
);
