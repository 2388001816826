import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { selectSessionIsLoggedIn } from '../../root-store/session/session.selectors';
import { clearAuthCookie } from '../../root-store/session/session.actions';
import { State } from '../../root-store/root-state';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private store: Store<State>,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.store.pipe(
        select(selectSessionIsLoggedIn),
        map(isLoggedIn => {
          if(!isLoggedIn) {
            this.store.dispatch(clearAuthCookie());
            this.authService.urlToRedirect = state.url;
          }
          return isLoggedIn;
        })
      );
  }
}
