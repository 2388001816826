import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { sessionReducer } from './session.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './session.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('session', sessionReducer),
    EffectsModule.forFeature([ SessionEffects ])
  ],
  providers: [
    SessionEffects
  ]
})
export class SessionModule { }
