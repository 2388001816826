import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { PayeePacks } from '../models/payee-packs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassThroughService {

  private base = 'payee-packs';

  constructor(
    private api: ApiService,
    private config: ConfigService
  ) { }

  getPayeePacks() {
    return this.api.get<PayeePacks[]>(
      this.apiUrl(`get-payee-packs`)
    );
  }


  getUserPayeePacks(userId: string): Observable<any> {
    return this.api.get<any>(this.apiUrl(`get-payee-packs-user`), { userId } );
  }

  savePayeePacks(userId: string, accounts: number[]): Observable<any> {
    return this.api.post<any>(this.apiUrl(`add-payee-packs-user`), {
      userId,
      accounts
    });
  }

  private apiUrl(path: string): string {
    return `${this.config.payeePacksUrl}${this.base}/${path}`;
  }
}
